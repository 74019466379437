import { FC, useEffect, useState } from "react";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import { useGetFirestoreDocumentData } from "@contexts/data";
import useToggle from "@hooks/use-toggle";
import { AccountType } from "@lib/data/schemas/account";
import { InvoiceType } from "@lib/data/schemas/invoice";
import { OutcomeType } from "@lib/data/schemas/outcome";

import OutcomeModalWrapper from "@components/Outcomes/OutcomeModalWrapper";
import AssignPackageSchedulerModalWrapper from "@components/Package/AssignPackageSchedulerModalWrapper";
import { SelectableList } from "@components/SelectableList";

import ReconciliationAppointmentTableListItem from "./ReconciliationAppointmentTableListItem";

const tableStyles = {
  wrapper: "flex-1 grid grid-cols-10 gap-2",
  date: "col-span-3",
  client: "col-span-3",
  package: "col-span-2",
  outcome: "col-span-2",
  sessions: "w-20 text-left",
  cutOffDate: "hidden md:block text-right",
  labels: "hidden md:block text-right",
  actions: "flex items-center",
};

interface ReconciliationAppointmentTablePlaceholderProps {
  showActions?: boolean;
  showMultiSelect?: boolean;
}

const ReconciliationAppointmentTablePlaceholder: FC<
  ReconciliationAppointmentTablePlaceholderProps
> = ({ showActions = true, showMultiSelect = true }) => {
  const style = "bg-grey-900 dark:bg-grey-250 animate-pulse";

  const renderItem = (index: number) => (
    <div
      placeholder={`table-placeholder-item-${index}`}
      className="flex items-center py-2 pb-2 z-10 w-full"
    >
      {showMultiSelect && (
        <div className={classNames(style, "rounded ml-4 mr-0 w-6 h-6")} />
      )}
      <div className="flex flex-1 space-x-4">
        <div
          className={classNames(
            tableStyles.wrapper,
            "flex text-grey-500 font-medium"
          )}
        >
          <div
            className={classNames(
              tableStyles.date,
              "flex items-center flex-1 text-left space-x-2"
            )}
          >
            <div className={classNames(style, "w-5 h-5 rounded-full")} />
            <div className={classNames(style, "w-20 h-1 rounded-full")} />
          </div>
          <div
            className={classNames(
              tableStyles.client,
              "flex items-center flex-1 text-left space-x-3"
            )}
          >
            <div className={classNames(style, "w-6 h-6 rounded-full")} />
            <div className={classNames(style, "w-20 h-1 rounded-full")} />
          </div>
          <div
            className={classNames(
              tableStyles.package,
              "flex items-center flex-1 text-left space-x-3"
            )}
          >
            <div className={classNames(style, "w-6 h-6 rounded-full")} />
            <div className={classNames(style, "w-20 h-1 rounded-full")} />
          </div>
          <div
            className={classNames(
              tableStyles.outcome,
              "flex items-center flex-1 text-left space-x-3"
            )}
          >
            <div className={classNames(style, "w-6 h-6 rounded-full")} />
            <div className={classNames(style, "w-20 h-1 rounded-full")} />
          </div>
          <div
            className={classNames(
              tableStyles.labels,
              "flex-1 text-center justify-end"
            )}
          >
            <div className="ml-auto w-16 border border-grey-900 rounded p-2">
              <div className="flex items-center space-x-1">
                <div className={classNames(style, "w-1 h-1 rounded-full")} />
                <div className={classNames(style, "w-16 h-1 rounded-full")} />
              </div>
            </div>
          </div>
        </div>
        {showActions && (
          <div className={classNames(tableStyles.actions, "w-6 h-6")}>
            <div className={classNames(style, "w-6 h-6 rounded")} />
          </div>
        )}
      </div>
    </div>
  );

  return <>{Array.from({ length: 10 }).map((_, index) => renderItem(index))}</>;
};

export interface ReconciliationAppointmentItemType {
  id: string;
  member: AccountType;
  date: Date;
  title: string;
  outcome: OutcomeType;
  hidden: boolean;
  isSelectionDisabled?: boolean;
}

interface ReconciliationAppointmentTableListProps {
  invoiceId: string;
  items: ReconciliationAppointmentItemType[];
  packageInstanceId?: string;
  onIntersection: () => void;
  isLoading?: boolean;
  onClickItem: (invoice: ReconciliationAppointmentItemType) => void;
  onUpdateAppointment?: (appointmentId: string) => void;
  onInvoiceUpdated?: () => void;
}
const ReconciliationAppointmentTableList: FC<
  ReconciliationAppointmentTableListProps
> = ({
  invoiceId,
  items,
  packageInstanceId,
  onClickItem,
  isLoading = false,
  onIntersection,
  onUpdateAppointment,
  onInvoiceUpdated,
}) => {
  const { oid } = useAuth();
  const { value: showOutcomeModal, toggle: toggleOutcomeModal } = useToggle();
  const { value: showAssignPackageModal, toggle: toggleAssignPackageModal } =
    useToggle();
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<
    string | null
  >(null);

  const { data } = useGetFirestoreDocumentData(
    oid && invoiceId ? `users/${oid}/invoices/${invoiceId}` : ""
  );

  const invoice: InvoiceType | undefined = data;

  const onClickOutcome = (item: ReconciliationAppointmentItemType) => {
    setSelectedAppointmentId(item.id);
    toggleOutcomeModal();
  };

  const onClickPackage = (item: ReconciliationAppointmentItemType) => {
    setSelectedAppointmentId(item.id);
    toggleAssignPackageModal();
  };

  const renderEmpty = (
    <p className="text-grey-500 text-sm text-center">
      No appointments found in this cycle.
    </p>
  );

  const renderOutcomeModal = () => {
    if (!showOutcomeModal || !selectedAppointmentId) return null;

    return (
      <OutcomeModalWrapper
        appointmentId={selectedAppointmentId}
        setShowModal={toggleOutcomeModal}
        onUpdateAppointment={onUpdateAppointment}
      />
    );
  };

  const renderAssignPackageModal = () => {
    if (!showAssignPackageModal || !selectedAppointmentId) return null;

    return (
      <AssignPackageSchedulerModalWrapper
        appointmentId={selectedAppointmentId}
        selectedPackageInstanceId={packageInstanceId}
        setShowModal={toggleAssignPackageModal}
        onUpdateAppointment={onUpdateAppointment}
      />
    );
  };

  useEffect(() => {
    onInvoiceUpdated?.();
  }, [invoice?.updatedAt.toISOString()]);

  return (
    <div className="pb-32 mt-2">
      <SelectableList
        items={items}
        emptyList={renderEmpty}
        loadByScreenSize={false}
        selectable={false}
        loadingPlaceholder={
          <ReconciliationAppointmentTablePlaceholder
            showActions={false}
            showMultiSelect={false}
          />
        }
        alwaysShowCheckbox
        styleOptions={{ withBorder: false, withoutHover: true }}
        isLoading={isLoading}
        header={
          <div className="flex flex-1 space-x-4">
            <div
              className={classNames(
                tableStyles.wrapper,
                "flex text-grey-500 text-sm"
              )}
            >
              <div className={classNames(tableStyles.date, "flex-1 text-left")}>
                Date
              </div>
              <div
                className={classNames(tableStyles.client, "flex-1 text-left")}
              >
                Appointment
              </div>
              <div
                className={classNames(tableStyles.package, "flex-1 text-left")}
              >
                Package
              </div>
              <div
                className={classNames(tableStyles.outcome, "flex-1 text-left")}
              >
                Outcome
              </div>
            </div>
          </div>
        }
        showMultiSelect={false}
        rowRenderer={(item) => {
          return (
            <ReconciliationAppointmentTableListItem
              item={item}
              tableStyles={tableStyles}
              onClickOutcome={onClickOutcome}
              onClickPackage={onClickPackage}
              onClickItem={onClickItem}
            />
          );
        }}
        trigger={{
          onIntersection,
          className: "!m-0",
        }}
      />
      {renderOutcomeModal()}
      {renderAssignPackageModal()}
    </div>
  );
};

export default ReconciliationAppointmentTableList;
