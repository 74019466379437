import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CreateIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4ZM2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6ZM12 7C12.5523 7 13 7.44772 13 8V10C13 10.5523 13.4477 11 14 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H14C13.4477 13 13 13.4477 13 14V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V14C11 13.4477 10.5523 13 10 13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H10C10.5523 11 11 10.5523 11 10V8C11 7.44772 11.4477 7 12 7Z"
      />
    </svg>
  );
};

export default CreateIcon;
