import { FC } from "react";
import classNames from "classnames";
import moment from "moment";

import { displayNameFromContact } from "@lib/contacts";

import { Button } from "@components/Button";
import ClientAvatar from "@components/Client/ClientAvatar";
import CheckIcon from "@components/Icons/CheckIcon";
import CreateIcon from "@components/Icons/CreateIcon";
import OutcomeIcon from "@components/Outcomes/OutcomeIcon";

import { ReconciliationAppointmentItemType } from "./ReconciliationAppointmentTableList";

interface ReconciliationAppointmentTableListItemProps {
  item: ReconciliationAppointmentItemType;
  tableStyles: any;
  onClickOutcome: (item: ReconciliationAppointmentItemType) => void;
  onClickPackage: (item: ReconciliationAppointmentItemType) => void;
  onClickItem: (item: ReconciliationAppointmentItemType) => void;
}

const ReconciliationAppointmentTableListItem: FC<
  ReconciliationAppointmentTableListItemProps
> = ({ item, tableStyles, onClickOutcome, onClickPackage, onClickItem }) => {
  return (
    <div className="flex flex-1 space-x-4">
      <div
        className={classNames(
          tableStyles.wrapper,
          "flex items-center py-2 cursor-pointer"
        )}
        onClick={() => onClickItem(item)}
      >
        <div className={classNames(tableStyles.date, "flex-1")}>
          <p>{moment(item.date).format("ddd. MMM D, YYYY")}</p>
          <p className="text-grey-500">{`${moment(item.date).format(
            "h:mm A"
          )}`}</p>
        </div>
        <div
          className={classNames(
            tableStyles.client,
            "flex-1 flex flex-col items-start gap-y-0.5 truncate"
          )}
        >
          {item.title}
          <div className="flex space-x-1 items-center">
            <ClientAvatar client={item.member} size="smaller" />

            <div className="text-grey-500">
              {displayNameFromContact(item.member)}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            tableStyles.package,
            "flex items-center flex-1 text-sm text-grey-500 truncate space-x-2"
          )}
        >
          {item.hasPackage ? (
            <Button className="text-ellipsis overflow-hidden flex items-center space-x-1 text-green-200 bg-green-800 p-2 hover:bg-green-800 active:bg-green-800">
              <CheckIcon />
              <span>Package</span>
            </Button>
          ) : (
            <Button
              className="text-ellipsis overflow-hidden flex items-center space-x-1 text-grey-500 p-2"
              onClick={() => onClickPackage(item)}
            >
              <CreateIcon />
              <span>Package</span>
            </Button>
          )}
        </div>
        <div
          className={classNames(
            tableStyles.outcome,
            "flex items-center flex-1 text-sm text-grey-500 truncate space-x-2"
          )}
        >
          {item.outcome ? (
            <div className="flex items-center gap-3 rounded-lg px-2 py-3">
              <OutcomeIcon
                outcome={item.outcome}
                withLabel
                className="w-4 h-4"
                iconClassName="p-1"
              />
            </div>
          ) : (
            <Button
              className="text-ellipsis overflow-hidden flex items-center space-x-1 text-grey-500 p-2"
              onClick={() => onClickOutcome(item)}
            >
              <CreateIcon />
              <span>Outcome</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReconciliationAppointmentTableListItem;
