import { useCallback, useMemo } from "react";
import { PackageTemplateApi } from "@practice/sdk";
import useSWR, { useSWRConfig } from "swr";

import { useAuth } from "@contexts/auth";
import { ExtendedPackageType } from "@lib/data/schemas/packages";

import { useSchedulers } from "./data/schedulers";
import useAccessType from "./use-access-type";
import { useSDKApi } from "./use-sdk-api";

type UsePackagesType = () => {
  packages?: ExtendedPackageType[];
  loading: boolean;
  getPackage: (packageId: string) => ExtendedPackageType | undefined;
};

export const usePackages: UsePackagesType = () => {
  const { accessType } = useAccessType();
  const { packages: allPackages, loading: loadingPackages } = useOrgPackages();
  const { data: schedulers, loading: loadingSchedulers } = useSchedulers();

  const packages = useMemo(() => {
    if (!accessType || !allPackages || !schedulers) return;

    if (accessType === "full") return allPackages;

    const schedulerIds = schedulers.map(({ id }) => id);

    return allPackages.filter(({ items }) => {
      return items.some(({ schedulerId }) =>
        schedulerIds.includes(schedulerId)
      );
    });
  }, [allPackages, schedulers, accessType]);

  const getPackage = useCallback(
    (packageId: string) => packages?.find(({ id }) => id === packageId),
    [packages]
  );

  return {
    packages,
    loading: loadingPackages || loadingSchedulers,
    getPackage,
  };
};

export const useOrgPackages: UsePackagesType = () => {
  const { oid } = useAuth();

  const packageTemplateApi = useSDKApi(PackageTemplateApi);
  const swrData = useSWR(
    `organizations/${oid}/package-templates`,
    async () => {
      if (!oid) return;
      return packageTemplateApi.getPackageTemplates({
        organizationId: oid,
        xRequestId: "use-org-packages",
      });
    },
    { dedupingInterval: 600000 }
  );

  const packages = swrData.data;

  const getPackage = useCallback(
    (packageId: string) =>
      packages?.find(({ id }) => id === packageId) as ExtendedPackageType,
    [packages]
  );

  return {
    packages: (packages as ExtendedPackageType[]) || undefined,
    loading: !!swrData?.isLoading,
    getPackage,
  };
};

/**
 * Get a package template
 * */
export const usePackageTemplate = (
  organizationId: string,
  packageTemplateId: string
) => {
  const packageTemplateApi = useSDKApi(PackageTemplateApi);
  const swrData = useSWR(
    `organizations/${organizationId}/package-templates/${packageTemplateId}`,
    async () => {
      if (!organizationId || !packageTemplateId) return;
      return packageTemplateApi.getPackageTemplate({
        organizationId,
        packageTemplateId,
        xRequestId: "use-package-template",
      });
    },
    { dedupingInterval: 600000 }
  );
  const data = swrData.data;
  return {
    packageTemplate: data,
    loading: (!data && !swrData.error) || swrData.isLoading,
    mutate: swrData.mutate,
  };
};

export const useMutatePackageTemplate = (packageTemplateId?: string) => {
  const { oid } = useAuth();
  const { mutate } = useSWRConfig();
  const path =
    oid && packageTemplateId
      ? `organizations/${oid}/package-templates/${packageTemplateId}`
      : null;
  return () => mutate(path);
};

export const useMutatePackageTemplates = () => {
  const { oid } = useAuth();
  const { mutate } = useSWRConfig();
  const path = oid ? `organizations/${oid}/package-templates` : null;
  return () => mutate(path);
};
