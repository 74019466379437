import { FC, ReactNode } from "react";
import {
  GetInvoice200ResponseClient,
  GetInvoice200ResponseMissingOutcomesInnerMember,
  GetInvoice200ResponseParent,
} from "@practice/sdk";
import classNames from "classnames";
import { kebabCase } from "lodash";
import moment from "moment";
import Link from "next/link";

import { displayNameFromContact } from "@lib/contacts";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { formatPrice } from "@lib/utils";
import pluralHelper from "@lib/utils/pluralHelper";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";
import SmallBanner from "@components/Banner/SmallBanner";
import { Button } from "@components/Button";
import ClientAvatar from "@components/Client/ClientAvatar";
import InfoIcon from "@components/Icons/InfoIcon";
import MailIcon from "@components/Icons/MailIcon";
import {
  Tooltip,
  TooltipSize,
  TooltipVariant,
} from "@components/Tooltips/Tooltip";

const ReconciliationOverviewPlaceholder: FC = () => {
  const style = "bg-grey-900 dark:bg-grey-250 animate-pulse rounded-full";
  return (
    <div className="rounded-lg border border-grey-900 bg-grey-950 flex flex-col divide-y">
      <div className="flex items-center justify-between py-5 p-3">
        <div className={classNames(style, "w-20 h-1")} />
        <div className={classNames(style, "w-20 h-1")} />
      </div>
      <div className="flex items-center justify-between p-3">
        <div className={classNames(style, "w-20 h-1")} />
        <div className="flex items-center space-x-2">
          <div className={classNames(style, "w-20 h-1")} />
          <div className={classNames(style, "w-4 h-4")} />
        </div>
      </div>
      <div className="flex items-center justify-between p-3">
        <div className={classNames(style, "w-20 h-1")} />
        <div className="flex items-center space-x-2">
          <div className={classNames(style, "w-8 h-4")} />
        </div>
      </div>
    </div>
  );
};

interface LineProps {
  label: string;
  value: ReactNode;
}

const Line: FC<LineProps> = ({ label, value }) => (
  <div className="flex items-center justify-between p-3 space-x-2">
    <p className="text-grey-500">{label}</p>
    <div className="font-medium text-black-ink">{value}</div>
  </div>
);

interface MissingOutcomeItemProps {
  appointmentId: string;
  status: AppointmentType["status"];
  title: string;
  date: Date;
  member?: GetInvoice200ResponseMissingOutcomesInnerMember;
}

const MissingOutcomeItem: FC<MissingOutcomeItemProps> = ({
  status,
  title,
  date,
  member,
}) => (
  <div className="flex items-center justify-between space-x-4 py-2 last:pb-0">
    <div>
      <p className="text-sm text-black-ink font-medium">
        {moment(date).format("ddd, MMM Do")}
      </p>
      <div className="flex items-center space-x-1">
        <ArtifactStatusIcon
          className="!w-5 !h-5"
          type="appointments"
          status={status}
          variant="status-icon"
          size="sm"
        />
        <ArtifactStatusText type="appointments" status={status} size="xs" />
      </div>
    </div>
    <div className="flex flex-1 items-center justify-end space-x-3 text-ellipsis overflow-hidden">
      <div className="truncate">
        <p className="text-sm text-right font-medium text-black-ink text-ellipsis overflow-hidden">
          {title}
        </p>
        <div className="flex items-center justify-end text-right space-x-2 text-xs font-medium text-grey-500">
          <ClientAvatar client={member ?? {}} size="xxsmall" className="mr-0" />
          <span className="truncate">{displayNameFromContact(member)}</span>
        </div>
      </div>
      <div>
        <Link href={`mailto:${member?.email}`}>
          <Button
            icon={<MailIcon />}
            component="span"
            variant="primary"
            square
            smaller
          />
        </Link>
      </div>
    </div>
  </div>
);

interface MissingOutcomesBagdeProps {
  total: number;
  missing: number;
  isComplete: boolean;
  isFromPastCycle?: boolean;
}

const MissingOutcomesBagde: FC<MissingOutcomesBagdeProps> = ({
  total,
  missing,
  isComplete,
  isFromPastCycle = false,
}) => {
  const getSyle = () => {
    if (isFromPastCycle) return "bg-grey-900 text-black-ink";
    if (isComplete) return "bg-green-600 text-green-200";
    return "bg-action-500 text-white";
  };

  return (
    <p
      className={classNames(
        "rounded-full text-sm font-medium px-2 py-0.5",
        getSyle()
      )}
    >
      {missing}/{total}
    </p>
  );
};

interface ReconciliationOverviewProps {
  amount: number;
  currency: string;
  invoiceNumber?: string;
  scheduledDate?: Date;
  sharedDate?: Date;
  paidDate?: Date;
  client?: GetInvoice200ResponseClient;
  totalSessionsInPackage: number;
  missingOutcomes?: MissingOutcomeItemProps[];
  totalSessionsInCycle: number;
  missingPackages?: MissingOutcomeItemProps[];
  isLoading?: boolean;
  invoiceMethod: "auto" | "manual";
  showRolloverBanner?: boolean;
  isFromPastCycle?: boolean;
  parent?: GetInvoice200ResponseParent;
}

const ReconciliationOverview: FC<ReconciliationOverviewProps> = ({
  amount,
  currency,
  invoiceNumber,
  scheduledDate,
  sharedDate,
  paidDate,
  client,
  totalSessionsInPackage,
  missingOutcomes,
  totalSessionsInCycle,
  missingPackages,
  isLoading = false,
  invoiceMethod,
  showRolloverBanner = false,
  isFromPastCycle = false,
  parent,
}) => {
  if (isLoading) return <ReconciliationOverviewPlaceholder />;

  const totalMissingOutcomes = missingOutcomes?.length ?? 0;
  const totalMissingPackages = missingPackages?.length ?? 0;
  const hasMissingOutcomes = totalMissingOutcomes > 0;
  const hasMissingPackages = totalMissingPackages > 0;
  const getFormattedDate = (date: Date) =>
    moment(date).utc().format("dddd, MMM DD YYYY");
  const isInvoiceMethodManual = invoiceMethod === "manual";

  const renderClient = (client?: GetInvoice200ResponseClient) => {
    if (!client) return null;
    return (
      <Link
        href={`/contacts/${client.id}`}
        className="flex items-center justify-end space-x-2 group"
      >
        <span className="group-hover:underline truncate max-w-[260px]">
          {displayNameFromContact(client)}
        </span>
        <ClientAvatar client={client} size="xsmall" />
      </Link>
    );
  };

  const renderOutcomes = () => {
    return (
      <div className="flex items-center space-x-2">
        <MissingOutcomesBagde
          total={totalSessionsInPackage}
          missing={totalSessionsInPackage - totalMissingOutcomes}
          isComplete={!hasMissingOutcomes}
          isFromPastCycle={isFromPastCycle}
        />
        {hasMissingOutcomes && (
          <Tooltip
            variant={TooltipVariant.white}
            size={TooltipSize.larger}
            trigger={<InfoIcon className="text-grey-500 w-4 h-4" />}
            contentClassName="!w-96 shadow-elevation-50 max-h-96 overflow-y-auto"
            placement="bottom-start"
          >
            <div className="space-y-1">
              {showRolloverBanner && (
                <SmallBanner
                  className="mb-4"
                  variant="dashed"
                  items={[
                    {
                      text: "Some sessions have been rolled over from the original invoice due to non-approval at the initial due date.",
                    },
                  ]}
                />
              )}
              <p className="flex items-center justify-between text-sm text-grey-500">
                <span>Missing outcomes</span>
                <span>
                  {pluralHelper(missingOutcomes?.length ?? 0, "session")}
                </span>
              </p>
              <div className="divide-y">
                {missingOutcomes?.map((item, index) => {
                  const key = kebabCase(
                    `missing-outcome-item-${item.appointmentId}-${index}`
                  );
                  return (
                    <MissingOutcomeItem
                      key={key}
                      appointmentId={item.appointmentId}
                      title={item.title}
                      date={item.date}
                      member={item.member}
                      status={item.status}
                    />
                  );
                })}
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const renderNotIncluded = () => {
    return (
      <div className="flex items-center space-x-2">
        <MissingOutcomesBagde
          total={totalSessionsInCycle}
          missing={totalSessionsInCycle - totalMissingPackages}
          isComplete={!hasMissingPackages}
          isFromPastCycle={isFromPastCycle}
        />
        {hasMissingPackages && (
          <Tooltip
            variant={TooltipVariant.white}
            size={TooltipSize.larger}
            trigger={<InfoIcon className="text-grey-500 w-4 h-4" />}
            contentClassName="!w-96 shadow-elevation-50 max-h-96 overflow-y-auto"
            placement="bottom-start"
          >
            <div className="space-y-1">
              {showRolloverBanner && (
                <SmallBanner
                  className="mb-4"
                  variant="dashed"
                  items={[
                    {
                      text: "Some sessions have been rolled over from the original invoice due to non-approval at the initial due date.",
                    },
                  ]}
                />
              )}
              <p className="flex items-center justify-between text-sm text-grey-500">
                <span>Missing packages</span>
                <span>
                  {pluralHelper(missingPackages?.length ?? 0, "session")}
                </span>
              </p>
              <div className="divide-y">
                {missingPackages?.map((item, index) => {
                  const key = kebabCase(
                    `missing-package-item-${item.appointmentId}-${index}`
                  );
                  return (
                    <MissingOutcomeItem
                      key={key}
                      appointmentId={item.appointmentId}
                      title={item.title}
                      date={item.date}
                      member={item.member}
                      status={item.status}
                    />
                  );
                })}
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <div className="rounded-lg border border-grey-900 bg-grey-950 flex flex-col divide-y">
      <Line label="Amount due" value={formatPrice(amount, currency)} />
      {invoiceNumber && <Line label="Invoice #" value={invoiceNumber} />}
      {scheduledDate && (
        <Line
          label={isInvoiceMethodManual ? "Due date" : "Scheduled for"}
          value={getFormattedDate(scheduledDate)}
        />
      )}
      {sharedDate && (
        <Line label="Shared on" value={getFormattedDate(sharedDate)} />
      )}
      {paidDate && <Line label="Paid on" value={getFormattedDate(paidDate)} />}
      <Line label="Client" value={renderClient(client)} />
      {parent && (
        <>
          <Line
            label={parent?.clientType === "company" ? "Company" : "Family"}
            value={renderClient(parent)}
          />
          <Line label={"Billed to"} value={parent.email} />
        </>
      )}
      <Line label="Session outcomes" value={renderOutcomes()} />
      <Line label="Session not included" value={renderNotIncluded()} />
    </div>
  );
};

export default ReconciliationOverview;
