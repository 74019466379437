import { FC } from "react";
import classNames from "classnames";

import FilledFlagIcon from "@components/Icons/FilledFlagIcon";

type ReconciliationFinalInvoiceSizesType = "xs" | "md";
type ReconciliationFinalInvoiceStylesType = Record<
  ReconciliationFinalInvoiceSizesType,
  {
    wrapper: string;
    icon: string;
  }
>;

const sizes: ReconciliationFinalInvoiceStylesType = {
  xs: {
    wrapper: "text-xs",
    icon: "w-4 h-4",
  },
  md: {
    wrapper: "text-base",
    icon: "w-6 h-6",
  },
};

interface ReconciliationFinalInvoiceProps {
  size?: ReconciliationFinalInvoiceSizesType;
}

const ReconciliationFinalInvoice: FC<ReconciliationFinalInvoiceProps> = ({
  size = "md",
}) => {
  const localSize = sizes[size];
  return (
    <p
      className={classNames(
        "flex items-center text-action-500 text-nowrap space-x-1",
        localSize.wrapper
      )}
    >
      <FilledFlagIcon className={localSize.icon} />
      <span className="flex-1 font-medium">Final invoice</span>
    </p>
  );
};

export default ReconciliationFinalInvoice;
