import { FC, ReactNode, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  postAppointmentOutcome,
  postCancelAppointment,
} from "api-services/definitions/appointments";
import { getContactAppointments } from "api-services/definitions/contact-appointments";
import { useApi, useApiGetMutate } from "api-services/endpoints";

import { useAuth } from "@contexts/auth";
import { useScheduler } from "@hooks/data/schedulers";
import useContact from "@hooks/use-contact";
import { usePackageInstancesMutate } from "@hooks/use-package-instances";
import useSnackbar from "@hooks/use-snackbar";
import { useUpdateAppointmentsCache } from "@hooks/useUpdateAppointmentsCache";
import { isAppointmentTodayOrPast } from "@lib/appointments";
import { displayNameFromContact } from "@lib/contacts";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { ClientType } from "@lib/data/schemas/client";
import { OutcomeType } from "@lib/data/schemas/outcome";

import ClientAvatar from "@components/Client/ClientAvatar";
import SearchableMultiSelectForm from "@components/Form/SelectForm/SearchableMultiSelectForm";
import TextAreaForm from "@components/Form/TextAreaForm";
import { ToggleFormComponent } from "@components/Form/Toggle";
import FlagIcon from "@components/Icons/Flag3Icon";
import InfoFilledIcon from "@components/Icons/InfoFilledIcon";
import LockIcon from "@components/Icons/LockIcon";
import SmallModal from "@components/Modals/SmallModal";

import OutcomeIcon, { OutcomeIconInfo } from "./OutcomeIcon";

export interface OutcomeModalProps {
  appointment: AppointmentType;
  outcomes: OutcomeType[];
  setShowModal: (showModal: boolean) => void;
  onUpdateAppointment?: (appointmentId: string) => void;
  groupOutcome?: { clientId: string };
}

export type OutcomeModalFieldTypes = {
  note: string;
  outcomeId: string;
  cancelCalendarEvent: boolean;
};

const OutcomeModal: FC<OutcomeModalProps> = ({
  appointment,
  outcomes,
  setShowModal,
  onUpdateAppointment,
  groupOutcome,
}) => {
  const { aid, oid } = useAuth();
  const { apiCall: updateAppointmentOutcome } = useApi(postAppointmentOutcome, {
    failMode: "throw",
  });
  const { apiCall: apiCancelAppointment } = useApi(postCancelAppointment);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const mutatePackages = usePackageInstancesMutate(
    appointment?.contactId ?? undefined
  );

  const isOutcomeOriginFromClient = appointment.outcome?.origin === "client";
  const { data: scheduler } = useScheduler(appointment.availabilityId);
  const minimumNotice =
    scheduler?.reschedulingOptions?.minimumNotice ??
    appointment.reschedulingOptions?.minimumNotice;
  const noticeInHours =
    minimumNotice?.duration && minimumNotice.duration > 0
      ? Math.ceil(minimumNotice.duration / 60)
      : 0;
  // @TODO: remove feature flag when we are ready to release
  const isCompletedOutcomeAvailable =
    process.env.PRACTICE_ENV !== "production"
      ? true
      : isAppointmentTodayOrPast(appointment);

  const contactId = appointment ? appointment.contactId : null;
  const { contact } = useContact(contactId ? contactId : undefined);

  const existingOutcome = groupOutcome
    ? appointment.attendeeOutcomes?.[groupOutcome.clientId]?.outcome
    : appointment.outcome;

  const formMethods = useForm<OutcomeModalFieldTypes>({
    defaultValues: {
      note: existingOutcome?.note ?? "",
      outcomeId: existingOutcome?.outcomeId,
      cancelCalendarEvent: true,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    control,
  } = formMethods;

  const outcomeId = watch("outcomeId");
  const allowedCancelledOutcomeCategoriesForCancelEvent = [
    "cancelled_inside_window",
    "cancelled",
  ];
  const outcome = outcomes.find((o) => o.id === outcomeId);
  const canCancelCalendarEvent = outcome
    ? allowedCancelledOutcomeCategoriesForCancelEvent.includes(
        outcome.category
      ) &&
      appointment.status !== "cancelled" &&
      !appointment.customEventInformation
    : false;

  const mutateContactAppointments = useApiGetMutate(
    oid && appointment.contactId ? getContactAppointments : undefined,
    {
      orgId: oid ?? "",
      contactId: appointment.contactId ?? "",
    },
    undefined,
    {
      ignoreQuery: true,
    }
  );
  const { onUpdateAppointment: onUpdateAppointmentInCache } =
    useUpdateAppointmentsCache();

  const onSubmit = async (data: OutcomeModalFieldTypes) => {
    setLoading(true);

    const outcomeData = {
      ...data,
      origin: "coach" as "coach" | "client",
    };

    try {
      if (canCancelCalendarEvent && outcomeData.cancelCalendarEvent === true) {
        const payload = {
          ...outcomeData,
          appointmentId: appointment.id,
          sendEmailNotifications: true,
          updateOutcome: true,
          reason: data.note,
        };

        await apiCancelAppointment({ userId: oid! }, payload, {});
      } else {
        const payload = {
          ...outcomeData,
          createdBy: aid!,
          ...(groupOutcome && { group: groupOutcome }),
        };

        await updateAppointmentOutcome(
          { userId: oid!, appointmentId: appointment.id },
          payload,
          {}
        );
      }
      await mutateContactAppointments();
      await onUpdateAppointmentInCache(appointment.id);
      onUpdateAppointment?.(appointment.id);
      await mutatePackages();
      snackbar.showMessage("Outcome saved successfully");
    } catch (e) {
      snackbar.showWarning("Error saving appointment outcome");
    } finally {
      setLoading(false);
    }
  };

  const description = (
    <h3 className="pt-4">
      Add context about this session. We&apos;ll reflect that outcome across the
      app.
    </h3>
  );

  const infoLabel = (
    outcome: OutcomeType,
    disabled: boolean
  ): ReactNode | undefined => {
    if (noticeInHours === 0) {
      return undefined;
    }

    let title: string | undefined;
    if (outcome.category === "cancelled_outside_window") {
      title = `Client cancels less than ${noticeInHours}hrs notice`;
    } else if (outcome.category === "cancelled_inside_window") {
      title = `Client cancels more than ${noticeInHours}hrs notice`;
    } else if (outcome.category === "completed" && disabled) {
      title = `Available on the day of the appointment`;
    }

    if (title) {
      return (
        <OutcomeIconInfo
          title={title}
          icon={
            !disabled && <InfoFilledIcon className="h-4 w-4 text-action-500" />
          }
          disabled={disabled}
        />
      );
    }

    return undefined;
  };

  const getLabelAndValue = (outcome: OutcomeType, disabled: boolean) => ({
    label: (
      <div className="flex items-center gap-3 hover:bg-grey-950 hover:cursor-pointer rounded-lg px-2 py-3">
        <OutcomeIcon
          outcome={outcome}
          disabled={disabled}
          withLabel
          className="w-4 h-4"
          iconClassName="p-1"
          info={infoLabel(outcome, disabled)}
          rightIcon={disabled && <LockIcon className="text-grey-800" />}
        />
      </div>
    ),
    value: outcome.id,
    disabled,
  });

  const options = outcomes?.map((o) =>
    getLabelAndValue(
      o,
      o.category === "completed" && !isCompletedOutcomeAvailable
    )
  );

  return (
    <SmallModal
      show
      toggleShow={setShowModal}
      title="How did this session go?"
      icon={FlagIcon}
      iconColor="grey"
      description={description}
      onAction={handleSubmit(onSubmit)}
      onActionText="Confirm outcome"
      onActionLoading={loading}
      isActionDisabled={!outcomeId}
    >
      <FormProvider {...formMethods}>
        <SearchableMultiSelectForm
          id="outcome-picker"
          name="outcomeId"
          options={options}
          parents={[]}
          type="outcomes"
          placeholder={
            <div className="!py-3" id="outcome-picker-select">
              Add an outcome
            </div>
          }
          required
          valueClassNames="!pointer-events-none"
          optionClassNames="!px-2 !py-0 active:!bg-white"
          menuClassNames="!py-2 h-48"
          emptyMessage="No outcomes found"
        />
        {canCancelCalendarEvent && (
          <div className="relative flex items-start mt-4 mb-2">
            <label
              htmlFor="cancelCalendarEvent"
              className="flex items-center text-sm leading-5 text-grey-500 w-full"
            >
              <span className="flex-1">Cancel calendar event</span>
              <ToggleFormComponent
                name="cancelCalendarEvent"
                size="small"
                control={control}
              />
            </label>
          </div>
        )}
        <TextAreaForm
          containerClassName="mt-1"
          textClassName="bg-white placeholder-grey-700 text-sm resize-none"
          placeholder="Add a note"
          name="note"
          register={register}
          errors={errors}
          minRows={2}
          disabled={!outcomeId}
          disabledTooltip="Select an outcome to add a note"
        />
        {isOutcomeOriginFromClient && (
          <div className="mt-4 flex gap-2 items-center text-sm">
            <ClientAvatar
              size="xsmall"
              client={contact as ClientType}
              className=" !mx-0"
            />
            <div className="flex flex-col">
              <h3>{displayNameFromContact(contact)} (client)</h3>
            </div>
          </div>
        )}
      </FormProvider>
    </SmallModal>
  );
};

export default OutcomeModal;
