import { FC } from "react";
import { GetInvoicePreview200ResponseTaxesInner } from "@practice/sdk";
import classNames from "classnames";
import moment from "moment";

import { formatPrice } from "@lib/utils";

const ReconciliationInvoicePreviewPlaceholder: FC = () => {
  const style = "bg-grey-900 dark:bg-grey-250 animate-pulse rounded-full";

  const renderLine = (index: number) => (
    <div
      key={`preview-placeolder-item-${index}`}
      className={classNames("grid grid-cols-6 py-4 gap-2")}
    >
      <div className="col-span-3">
        <div className={classNames("w-20 h-1", style)} />
      </div>
      <div className="flex justify-end">
        <div className={classNames("w-2 h-1", style)} />
      </div>
      <div className="flex justify-end">
        <div className={classNames("w-8 h-1", style)} />
      </div>
      <div className="flex justify-end">
        <div className={classNames("w-8 h-1", style)} />
      </div>
    </div>
  );

  const renderResumeItem = (index: number) => (
    <div
      key={`preview-resume-item-${index}`}
      className={classNames("grid grid-cols-6 py-4")}
    >
      <div className="col-span-5">
        <div className={classNames("w-20 h-1 ml-auto", style)} />
      </div>
      <div className={classNames("w-8 h-1 ml-auto", style)} />
    </div>
  );

  return (
    <div>
      <div className="divide-y mx-5">
        {Array.from({ length: 5 }, (_, index) => renderLine(index))}
        {Array.from({ length: 2 }, (_, index) => renderResumeItem(index))}
      </div>
    </div>
  );
};

type ReconciliationInvoiceItemType = {
  title: string;
  date: Date;
  quantity: number;
  rate: number;
  amount: number;
  isRollover?: boolean;
};

interface ReconciliationInvoicePreviewProps {
  items: ReconciliationInvoiceItemType[];
  total: number;
  subTotal: number;
  taxes?: GetInvoicePreview200ResponseTaxesInner[];
  currency: string;
  isLoading?: boolean;
  depositBalance: number;
  updatedBalance: number;
  isCurrentCycle: boolean;
  overageAmount?: number;
  dueDate?: Date;
}

const ReconciliationInvoicePreview: FC<ReconciliationInvoicePreviewProps> = ({
  items,
  total,
  subTotal,
  taxes,
  currency,
  isLoading = false,
  depositBalance,
  updatedBalance,
  isCurrentCycle,
  overageAmount,
  dueDate,
}) => {
  if (isLoading) return <ReconciliationInvoicePreviewPlaceholder />;

  const tableStyles = {
    wrapper: "grid grid-cols-6 gap-2 text-grey-500 text-sm",
    description: "col-span-3 text-left",
    quantity: "text-right",
    rate: "text-right",
    amount: "text-right",
  };

  const getFormattedPrice = (value: number) =>
    formatPrice(value, currency, "en-US", false);

  const renderLine = (item: ReconciliationInvoiceItemType, index: number) => {
    const baseColStyle =
      "text-black-ink font-medium flex items-center justify-end text-right";
    return (
      <div
        className={classNames(tableStyles.wrapper, "py-1")}
        key={`invoice-line-item-${index}`}
      >
        <div className={classNames(tableStyles.description, "flex flex-col")}>
          <p className="truncate">{item.title}</p>
          <p className="text-xs truncate">
            {item.title === "Subscription"
              ? "Automatically added to package"
              : moment(item.date).utc().format("MMMM D, YYYY")}
            {item?.isRollover && (
              <>
                {" · "}
                <span className="font-medium text-blue-500">
                  Roll over session
                </span>
              </>
            )}
          </p>
        </div>
        <p className={classNames(tableStyles.quantity, baseColStyle, "pr-2")}>
          {item.quantity}
        </p>
        <p className={classNames(tableStyles.rate, baseColStyle)}>
          {getFormattedPrice(item.rate)}
        </p>
        <p className={classNames(tableStyles.amount, baseColStyle)}>
          {getFormattedPrice(item.amount)}
        </p>
      </div>
    );
  };

  const renderResumeItem = (label: string, value: number, isTotal = false) => (
    <div
      className={classNames(
        "grid grid-cols-6 text-right py-2",
        isTotal ? "!text-base !border-b" : "text-sm"
      )}
    >
      <p className="col-span-5 text-grey-500 font-medium pr-2">{label}</p>
      <p className="font-medium text-black-ink">{getFormattedPrice(value)}</p>
    </div>
  );

  const renderDeposit = (label: string, value: number) => {
    const isFuture = dueDate && dueDate > new Date();
    const shouldShowPending = isFuture && !isCurrentCycle && value === 0;
    return (
      <div
        className={classNames("grid grid-cols-6 text-right py-2", "text-sm")}
      >
        <p className="col-span-5 text-grey-500 font-medium pr-2">{label}</p>
        <p className="font-medium text-black-ink">
          {shouldShowPending ? (
            <p className="col-span-5 text-grey-600 font-medium pr-2">Pending</p>
          ) : (
            getFormattedPrice(value)
          )}
        </p>
      </div>
    );
  };

  return (
    <div>
      <div className={classNames(tableStyles.wrapper, "border-b px-5 py-3")}>
        <p className={tableStyles.description}>Description</p>
        <p className={tableStyles.quantity}>Qty</p>
        <p className={tableStyles.rate}>Rate</p>
        <p className={tableStyles.amount}>Amount</p>
      </div>
      <div className="divide-y mx-5">
        {items.map(renderLine)}
        {overageAmount && overageAmount >= 0 ? (
          renderResumeItem("Overage amount", overageAmount)
        ) : (
          <></>
        )}
        {renderDeposit("Deposit balance", depositBalance)}
        {renderResumeItem("Subtotal", subTotal)}
        {!!taxes &&
          taxes.map((tax, index) => (
            <div key={`preview-tax-${index}`}>
              {renderResumeItem(tax?.label ?? "", tax?.amount ?? 0)}
            </div>
          ))}
        {renderResumeItem("Amount due", total, true)}
        {renderDeposit("Updated balance", updatedBalance)}
      </div>
    </div>
  );
};

export default ReconciliationInvoicePreview;
