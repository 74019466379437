import { FC, ReactNode } from "react";
import classNames from "classnames";

import Modal from "@components/Modals/Modal";

export interface SideBigModalProps {
  trigger?: ReactNode;
  show?: boolean;
  toggleShow?: (value: boolean) => void;
  children?: ReactNode;
  className?: string;
  small?: boolean;
}

const SideBigModal: FC<SideBigModalProps> = ({
  trigger,
  show,
  toggleShow,
  children,
  className,
  small,
}) => {
  if (!trigger && !show) return null;
  return (
    <Modal
      className={classNames("overflow-y-auto", className)}
      trigger={trigger}
      show={show}
      toggleShow={toggleShow}
      size={small ? "side" : "side_big"}
    >
      {children}
    </Modal>
  );
};

export default SideBigModal;
