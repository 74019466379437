import { FC, useEffect } from "react";
import classNames from "classnames";
import { format } from "date-fns";

import { useAuth } from "@contexts/auth";
import {
  useApproveInvoice,
  useReconciliationInvoicePreview,
  useUnapproveInvoice,
} from "@hooks/data/invoices";
import useSnackbar from "@hooks/use-snackbar";
import useToggle from "@hooks/use-toggle";

import { Button } from "@components/Button";
import CloseIcon from "@components/Icons/CloseIcon";
import SideBigModal, {
  SideBigModalProps,
} from "@components/Modals/SideBigModal";
import UnapproveInvoiceModal from "@components/Modals/UnapproveInvoiceModal";
import { SelectableListContextProvider } from "@components/SelectableList";

import ReconciliationAppointmentTableList from "./ReconciliationAppointmentTableList";
import ReconciliationCardStatus from "./ReconciliationCardStatus";
import ReconciliationFinalInvoice from "./ReconciliationFinalInvoice";
import ReconciliationInvoicePreview from "./ReconciliationInvoicePreview";
import ReconciliationOverview from "./ReconciliationOverview";
import ReconciliationSidebarFooter from "./ReconciliationSidebarFooter";

const ReconciliationFinalInvoiceBanner: FC = () => (
  <div className="border border-dashed border-grey-900 rounded-xl p-3 space-y-2">
    <ReconciliationFinalInvoice />
    <p className="text-grey-500">
      Refund from the initial deposit may be reflected in the detailed invoice
      below.
    </p>
  </div>
);

interface ReconciliationSidebarProps extends SideBigModalProps {
  clientId: string;
  invoiceId: string;
  onClickClose: () => void;
  allInvoiceIds?: string[];
  onChangeInvoiceId?: (invoiceId: string) => void;
  onApprovedInvoice?: (invoiceId: string) => void;
  onUnapprovedInvoice?: (invoiceId: string) => void;
}

const ReconciliationSidebar: FC<ReconciliationSidebarProps> = ({
  clientId,
  invoiceId,
  onClickClose,
  allInvoiceIds = [],
  onChangeInvoiceId,
  onApprovedInvoice,
  onUnapprovedInvoice,
  ...props
}) => {
  const { oid } = useAuth();
  const {
    value: showUnapproveInvoiceModal,
    toggle: toggleUnapproveInvoiceModal,
  } = useToggle();
  const { data, loading, error, mutate } = useReconciliationInvoicePreview({
    invoiceId,
  });
  const {
    approveInvoice,
    loading: isApproveLoading,
    errorApprove,
    clearErrors: clearApproveErrors,
  } = useApproveInvoice();
  const {
    unapproveInvoice,
    loading: isUnapproveLoading,
    errorUnapprove,
    clearErrors: clearUnapproveErrors,
  } = useUnapproveInvoice();
  const snackbar = useSnackbar();

  const isPending = ["pre-approved", "review-needed"].includes(data?.status);
  const isPaid = ["paid", "failed"].includes(data?.status);
  const isApproved = data?.isApproved === true;
  const canUnapprove = !isPending && !isPaid && isApproved;
  const startDate = data?.cycle?.start
    ? format(data.cycle.start, "MMM d, yyyy")
    : undefined;
  const endDate = data?.cycle?.end
    ? format(data.cycle.end, "MMM d, yyyy")
    : undefined;

  useEffect(() => {
    if (errorApprove) {
      snackbar.showWarning("Error approving invoice", errorApprove);
      clearApproveErrors();
    } else if (errorUnapprove) {
      snackbar.showWarning("Error unapproving invoice", errorUnapprove);
      clearUnapproveErrors();
    }
  }, [errorApprove, errorUnapprove]);

  useEffect(() => {
    if (error) {
      snackbar.showWarning("Error fetching invoice preview");
    }
  }, [error]);

  useEffect(() => {
    mutate();
  }, []);

  const currentIndex = allInvoiceIds.findIndex((id) => id === invoiceId);
  const nextInvoiceId = allInvoiceIds[currentIndex + 1];
  const previousInvoiceId = allInvoiceIds[currentIndex - 1];

  const handleClickNext = () => {
    onChangeInvoiceId?.(nextInvoiceId);
  };

  const handleClickPrevious = () => {
    onChangeInvoiceId?.(previousInvoiceId);
  };

  const handleClickAction = async (autoCloseModal: boolean) => {
    if (canUnapprove) {
      toggleUnapproveInvoiceModal();
      return;
    }

    const approved = await approveInvoice(oid ?? "", invoiceId);

    if (!approved) return;
    snackbar.showMessage("Invoice approved", "success");
    onApprovedInvoice?.(invoiceId);

    if (autoCloseModal) {
      onClickClose();
    } else {
      onChangeInvoiceId?.(nextInvoiceId);
    }
  };

  const handleUnapproveInvoice = async () => {
    const unapproved = await unapproveInvoice(oid ?? "", invoiceId);

    if (!unapproved) return;
    snackbar.showMessage("Invoice unapproved", "success");
    onUnapprovedInvoice?.(invoiceId);
    onChangeInvoiceId?.(nextInvoiceId);
  };

  const renderHeader = (
    <div className={classNames("px-5 pt-5 lg:hidden", isPaid && "!flex")}>
      <Button
        icon={<CloseIcon />}
        onClick={onClickClose}
        square
        circle
        smaller
      />
    </div>
  );

  const allAppointmentsInCycle =
    data?.appointmentsInCycle.map((a) => {
      return {
        appointmentId: a.id,
        ...a,
      };
    }) ?? [];
  const unreconciledAppointments = allAppointmentsInCycle.filter(
    (appointment) => {
      return !appointment.hasPackage || !appointment.outcome;
    }
  );

  const renderInfo = () => {
    const missingOutcomes = allAppointmentsInCycle.filter(
      (a) => !a.outcome && a.hasPackage
    );
    const totalSessionsInPackage = allAppointmentsInCycle.filter(
      (a) => a.hasPackage
    ).length;
    const missingPackages = allAppointmentsInCycle.filter((a) => !a.hasPackage);
    const totalSessionsInCycle = allAppointmentsInCycle.length;
    const hasRollover = data?.items?.some((item) => item.isRollover) ?? false;
    const showCardStatus =
      (missingOutcomes.length > 0 && data?.status === "review-needed") ||
      data?.status !== "review-needed";

    return (
      <div className="grid gap-5 p-5">
        {showCardStatus && (
          <ReconciliationCardStatus status={data?.status} isLoading={loading} />
        )}
        <ReconciliationOverview
          isLoading={loading}
          amount={data?.totalAmount ?? 0}
          currency={data?.currency ?? ""}
          client={data?.client}
          parent={data?.parent}
          sharedDate={data?.sharedDate}
          paidDate={data?.paidDate}
          scheduledDate={data?.scheduledDate}
          totalSessionsInPackage={totalSessionsInPackage}
          missingOutcomes={missingOutcomes ?? []}
          totalSessionsInCycle={totalSessionsInCycle}
          missingPackages={missingPackages ?? []}
          invoiceMethod={data?.invoiceMethod ?? "auto"}
          showRolloverBanner={hasRollover}
          isFromPastCycle={data?.isFromPastCycle}
        />
        {data?.isLastInvoice && <ReconciliationFinalInvoiceBanner />}
      </div>
    );
  };

  const depositBalance = data?.depositBalance ?? 0;

  const renderInvoicePreview = (
    <ReconciliationInvoicePreview
      isLoading={loading}
      currency={data?.currency ?? ""}
      total={data?.totalAmount ?? 0}
      subTotal={data?.subTotal ?? 0}
      taxes={data?.taxes}
      items={data?.items ?? []}
      updatedBalance={data?.updatedBalance ?? 0}
      depositBalance={depositBalance == 0 ? 0 : -1 * depositBalance}
      isCurrentCycle={data?.isCurrentCycle ?? false}
      overageAmount={data?.overageAmount}
      dueDate={data?.dueDate}
    />
  );

  const renderUnapproveInvoiceModal = () => {
    if (!canUnapprove) return null;

    return (
      <UnapproveInvoiceModal
        show={showUnapproveInvoiceModal}
        toggleShow={toggleUnapproveInvoiceModal}
        onAction={handleUnapproveInvoice}
        onActionLoading={isUnapproveLoading}
      />
    );
  };

  return (
    <SideBigModal className="!py-0 flex !flex-row" {...props} small={isPaid}>
      <div
        className={classNames(
          "hidden flex-1 flex-col overflow-y-auto",
          !isPaid && "lg:flex"
        )}
      >
        <div className="h-[96px] flex items-center justify-between px-6 border-b gap-6">
          <div className="flex items-center gap-6">
            <Button icon={<CloseIcon />} square onClick={onClickClose} />

            <div className="flex-1">
              <h3 className="text-2xl text-black-ink leading-tight">
                Reconcile appointments
              </h3>
              {startDate && endDate && (
                <div className="text-grey-500 hidden sm:flex items-center mt-1 gap-2">
                  <p className="text-sm leading-5">
                    Review appointments between {startDate} and {endDate}.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="p-6 overflow-y-auto flex-1">
          {loading ? (
            <div className="flex items-center justify-center p-4">
              {/* <Spinner /> */}
              Loading...
            </div>
          ) : (
            <SelectableListContextProvider>
              <ReconciliationAppointmentTableList
                invoiceId={invoiceId}
                items={unreconciledAppointments}
                packageInstanceId={data?.packageInstance?.id}
                onClickItem={() => {}}
                isLoading={loading}
                onIntersection={() => {}}
                onUpdateAppointment={() => mutate()}
                onInvoiceUpdated={() => mutate()}
              />
            </SelectableListContextProvider>
          )}
        </div>
        <div className="bg-grey-950 h-[75px] flex border-t border-grey-900"></div>
      </div>

      <div className="w-full lg:w-105 flex flex-col border-l border-grey-900">
        {renderHeader}
        <div className="flex-1 overflow-y-auto">
          {renderInfo()}
          {renderInvoicePreview}
        </div>
        {!loading && (
          <ReconciliationSidebarFooter
            status={data?.status}
            isApproved={isApproved}
            onClickAction={handleClickAction}
            onClickNext={handleClickNext}
            onClickPrevious={handleClickPrevious}
            disabledNext={!nextInvoiceId}
            disabledPrevious={!previousInvoiceId}
            actionLoading={isApproveLoading || isUnapproveLoading}
            showNavButtons={allInvoiceIds.length > 0}
            invoiceUrl={`/clients/${clientId}/invoices/${invoiceId}`}
          />
        )}
        {renderUnapproveInvoiceModal()}
      </div>
    </SideBigModal>
  );
};

export default ReconciliationSidebar;
