import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import Link from "next/link";

import { Button } from "@components/Button";
import CheckIcon from "@components/Icons/CheckIcon";
import CloseIcon from "@components/Icons/CloseIcon";
import LeftArrowIcon from "@components/Icons/LeftArrowIcon";
import RightArrowIcon from "@components/Icons/RightArrowIcon";
import ViewOnIcon from "@components/Icons/ViewOnIcon";

interface ArrowButtonProps {
  onClick: () => void;
  disabled?: boolean;
  direction: "next" | "previous";
}
const ArrowButton: FC<ArrowButtonProps> = ({
  onClick,
  disabled,
  direction,
}) => {
  const Icon = direction === "next" ? RightArrowIcon : LeftArrowIcon;
  return (
    <Button
      className={classNames(
        "border hover:bg-grey-900 focus:bg-grey-900 active:bg-grey-900",
        `button-${direction}`
      )}
      icon={<Icon />}
      onClick={onClick}
      disabled={disabled}
      square
      smaller
    />
  );
};

export type ReconciliationStatusTypes =
  | "review-needed"
  | "pre-approved"
  | "approved-auto-charge"
  | "approved-manual"
  | "shared"
  | "paid"
  | "failed";

export interface ReconciliationSidebarFooterProps {
  invoiceUrl: string;
  onClickAction: (autoCloseModal: boolean) => void;
  onClickPrevious: () => void;
  onClickNext: () => void;
  disabledPrevious?: boolean;
  disabledNext?: boolean;
  status: ReconciliationStatusTypes;
  isApproved: boolean;
  actionLoading?: boolean;
  showNavButtons?: boolean;
  disabledAction?: boolean;
}

const ReconciliationSidebarFooter: FC<ReconciliationSidebarFooterProps> = ({
  invoiceUrl,
  onClickAction,
  onClickPrevious,
  onClickNext,
  disabledPrevious,
  disabledNext,
  disabledAction = false,
  status,
  isApproved,
  actionLoading = false,
  showNavButtons = true,
}) => {
  const isPending = ["pre-approved", "review-needed"].includes(status);
  const isInvoiceFinalized = ["paid", "failed"].includes(status);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.shiftKey) {
        setIsShiftPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.shiftKey) {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const getActionLabel = () => {
    const primaryLabel = isShiftPressed
      ? "Approve invoice and continue"
      : "Approve invoice";
    if (isPending && !isApproved) return primaryLabel;
    if (isInvoiceFinalized) return "View invoice";
    return "Unapprove";
  };

  const getActionIcon = () => {
    if (isInvoiceFinalized) return <ViewOnIcon />;
    if (isPending || isApproved) return <CheckIcon />;
    return <CloseIcon />;
  };

  const renderButton = () => {
    const button = (
      <Button
        icon={getActionIcon()}
        variant={isPending || isInvoiceFinalized ? "primary" : "peach"}
        disabled={disabledAction}
        onClick={
          isInvoiceFinalized ? undefined : () => onClickAction(!isShiftPressed)
        }
        component={isInvoiceFinalized ? "span" : "button"}
        isLoading={actionLoading}
        smaller
      >
        {getActionLabel()}
      </Button>
    );
    return isInvoiceFinalized ? (
      <Link href={invoiceUrl}>{button}</Link>
    ) : (
      button
    );
  };

  return (
    <div
      className={classNames(
        "bg-grey-950 p-5 flex border-t border-grey-900",
        showNavButtons ? "justify-between" : "justify-center"
      )}
    >
      {showNavButtons && (
        <ArrowButton
          direction="previous"
          onClick={onClickPrevious}
          disabled={disabledPrevious}
        />
      )}
      {renderButton()}
      {showNavButtons && (
        <ArrowButton
          direction="next"
          onClick={onClickNext}
          disabled={disabledNext}
        />
      )}
    </div>
  );
};

export default ReconciliationSidebarFooter;
