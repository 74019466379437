import CloseIcon from "@components/Icons/CloseIcon";
import SmallModal from "@components/Modals/SmallModal";

type Props = {
  show: boolean;
  toggleShow: (val: boolean) => void;
  onAction: (val: void) => void;
  onActionLoading: boolean;
};

const UnapproveInvoiceModal: React.FC<Props> = ({
  show,
  toggleShow,
  onAction,
  onActionLoading,
}) => {
  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      disableClose
      icon={CloseIcon}
      iconColor="grey"
      title="Unapprove invoice?"
      description="Unapproving an invoice will delete the scheduled invoice and require approval once again."
      onAction={onAction}
      onActionText="Confirm"
      onActionLoading={onActionLoading}
      heapModalName="unapprove_invoice_modal"
    />
  );
};

export default UnapproveInvoiceModal;
