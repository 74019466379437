import { FC } from "react";
import classNames from "classnames";

import ArtifactStatusIcon from "@components/ArtifactStatusIcon";

type ReconciliationStatusTypes =
  | "review-needed"
  | "rollover"
  | "pre-approved"
  | "approved-auto-charge"
  | "approved-manual"
  | "shared"
  | "paid"
  | "failed";

interface ReconciliationCardStatusProps {
  status: ReconciliationStatusTypes;
  isLoading?: boolean;
}

type DataType = Record<
  ReconciliationStatusTypes,
  {
    label: string;
    description?: string;
    labelStyle: string;
    cardStyle: string;
  }
>;

export const data: DataType = {
  "review-needed": {
    label: "Missing outcomes",
    description:
      "Only sessions with an outcome that match the package counting rules are included in invoices.",
    labelStyle: "text-action-400",
    cardStyle: "bg-gradient-to-t from-white to-action-900/50",
  },
  rollover: {
    label: "Rolled over",
    description:
      "Some sessions with missing outcomes have been rolled over to a future cycle",
    labelStyle: "text-blue-500",
    cardStyle: "bg-gradient-to-t from-white to-blue-500/20",
  },
  "pre-approved": {
    label: "Ready to be sent",
    labelStyle: "text-black-ink",
    cardStyle: "bg-gradient-to-t from-white to-grey-950",
  },
  "approved-auto-charge": {
    label: "Approved for auto-charge",
    labelStyle: "text-black-ink",
    cardStyle: "bg-gradient-to-t from-white to-green-900/50",
  },
  "approved-manual": {
    label: "Approved for manual invoicing",
    labelStyle: "text-black-ink",
    cardStyle: "bg-gradient-to-t from-white to-green-900/50",
  },
  shared: {
    label: "Shared",
    labelStyle: "text-blue-300",
    cardStyle: "bg-gradient-to-t from-white to-blue-900/50",
  },
  paid: {
    label: "Payment received",
    labelStyle: "text-green-200",
    cardStyle: "bg-gradient-to-t from-white to-green-900/50",
  },
  failed: {
    label: "Payment failed",
    labelStyle: "text-peach-400",
    cardStyle: "bg-gradient-to-t from-white to-peach-800/30",
  },
};

const ReconciliationCardStatusPlaceholder: FC = () => {
  const style = "bg-grey-900 dark:bg-grey-250 animate-pulse";
  return (
    <div className="border border-grey-900 py-2 px-3 rounded-xl">
      <div className="flex items-center">
        <div className="flex-1">
          <p className="text-sm text-grey-500">Status</p>
          <p className={classNames("w-20 h-1 rounded-full my-2", style)} />
        </div>
        <div className="w-8 h-8 bg-grey-900 rounded-full" />
      </div>
    </div>
  );
};

const ReconciliationCardStatus: FC<ReconciliationCardStatusProps> = ({
  status,
  isLoading = false,
}) => {
  if (isLoading) return <ReconciliationCardStatusPlaceholder />;

  const {
    label,
    labelStyle,
    cardStyle,
    description = null,
  } = data[status] ?? {};
  return (
    <div
      className={classNames(
        "border border-grey-900 py-2 px-3 rounded-xl space-y-2",
        cardStyle
      )}
    >
      <div className="flex items-center">
        <div className="flex-1">
          <p className="text-sm text-grey-500">Status</p>
          <p className={classNames("text-base font-medium", labelStyle)}>
            {label}
          </p>
        </div>
        <ArtifactStatusIcon
          status={status}
          type="invoices"
          variant="status-icon"
          size="md"
        />
      </div>
      {description && (
        <p className={classNames("text-sm", labelStyle)}>{description}</p>
      )}
    </div>
  );
};

export default ReconciliationCardStatus;
