import { FC } from "react";

import { useAuth } from "@contexts/auth";
import useAppointment from "@hooks/use-appointment";
import useOutcomes from "@hooks/use-outcomes";

import FlagIcon from "@components/Icons/Flag3Icon";
import SmallModal from "@components/Modals/SmallModal";

import OutcomeModal from "./OutcomeModal";

export interface OutcomeModalWrapperProps {
  appointmentId: string;
  setShowModal: (showModal: boolean) => void;
  onUpdateAppointment?: (appointmentId: string) => void;
}

const OutcomeModalWrapper: FC<OutcomeModalWrapperProps> = ({
  appointmentId,
  setShowModal,
  onUpdateAppointment,
}) => {
  const { oid } = useAuth();
  const { outcomes } = useOutcomes();
  const { appointment } = useAppointment(oid, appointmentId);

  if (!outcomes || !appointment) {
    return (
      <SmallModal
        show
        toggleShow={setShowModal}
        title="How did this session go?"
        icon={FlagIcon}
        iconColor="grey"
        description={
          <h3 className="pt-4">
            Add context about this session. We&apos;ll reflect that outcome
            across the app.
          </h3>
        }
        onActionText="Confirm outcome"
        onActionLoading={true}
      ></SmallModal>
    );
  }

  return (
    <OutcomeModal
      appointment={appointment}
      outcomes={outcomes}
      setShowModal={setShowModal}
      onUpdateAppointment={onUpdateAppointment}
    />
  );
};

export default OutcomeModalWrapper;
