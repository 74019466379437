import { FC } from "react";

import { useAuth } from "@contexts/auth";
import { useParentContact } from "@hooks/data/clients";
import useAppointment from "@hooks/use-appointment";
import useLinkPackage from "@hooks/use-link-package";
import usePackageInstances from "@hooks/use-package-instances";
import { usePackageSchedulers } from "@hooks/use-package-schedulers";
import { usePackages } from "@hooks/use-packages";

import EventAfterIcon from "@components/Icons/EventAfterIcon";
import SmallModal from "@components/Modals/SmallModal";

import AssignPackageSchedulerModal from "./AssignPackageSchedulerModal";

export interface AssignPackageSchedulerModalWrapperProps {
  appointmentId: string;
  selectedPackageInstanceId?: string;
  setShowModal: (showModal: boolean) => void;
  onUpdateAppointment?: (appointmentId: string) => void;
  onAddSeries?: (packageInstanceId: string) => void;
}

const AssignPackageSchedulerModalWrapper: FC<
  AssignPackageSchedulerModalWrapperProps
> = ({
  appointmentId,
  selectedPackageInstanceId,
  setShowModal,
  onUpdateAppointment,
  onAddSeries,
}) => {
  const { oid } = useAuth();
  const { appointment } = useAppointment(oid, appointmentId);
  const { packages } = usePackages();

  const clientId = appointment?.contactId ?? "";

  const { parentContact } = useParentContact(clientId);
  const { packageInstances } = usePackageInstances(
    oid,
    clientId,
    parentContact?.id
  );

  const { link } = useLinkPackage({
    coachId: oid,
    clientId: clientId,
  });

  const { packageSchedulers } = usePackageSchedulers(
    clientId,
    undefined,
    undefined,
    parentContact?.id
  );

  const linkAppt = async (
    packageInstanceId: string,
    packageInstanceOwnerId: string | undefined,
    appointmentId: string,
    schedulerId?: string
  ) => {
    await link({
      appointmentId,
      packageInstanceId,
      packageInstanceOwnerId,
      availabilityId: schedulerId,
    });
  };

  const handleAddSeries = (packageInstanceId: string) => {
    onAddSeries?.(packageInstanceId);

    setShowModal(false);
  };

  if (!packages || !packageInstances || !packageSchedulers || !appointment) {
    return (
      <SmallModal
        show
        toggleShow={setShowModal}
        title="Add appointment to package"
        icon={EventAfterIcon}
        iconColor="green"
        description="Select the scheduler this appointment will be assigned to. It will update the specific scheduler and package counter."
        onActionText="Assign to package"
        onActionLoading={true}
      ></SmallModal>
    );
  }

  return (
    <AssignPackageSchedulerModal
      packages={packages ?? []}
      packageInstances={packageInstances ?? []}
      packageSchedulers={packageSchedulers ?? []}
      selectedPackageInstanceId={selectedPackageInstanceId}
      toggleShow={() => setShowModal(false)}
      show
      appointment={appointment}
      linkAppt={linkAppt}
      onUpdateAppointment={onUpdateAppointment}
      onAddSeries={handleAddSeries}
    />
  );
};

export default AssignPackageSchedulerModalWrapper;
